import React, {useState} from 'react';
import './Login.css';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import config from '../../config';

const Login = ({onLogin}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state
    const navigate = useNavigate();

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        return password.length >= minLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setLoading(true); // Start loading
        if (!validatePassword(password)) {
            setError('Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.');
            setLoading(false);
            return;
        }
        // We do NOT USER THE AXIOS REQUESTS MANAGER WHEN LOGGING IN AS THE TOKENS HAVE NOT BEEN GENERATED YET
        // THEREFORE WE CANNOT MANAGE THEM. Use vanilla axios.
        try {
            if (isRegistering) {
                await axios.post(`${config.apiBaseUrl}/register`, {username, password, email});
                setIsRegistering(false);
                alert('Registration successful. Please check your email for the authentication link.');
            } else {
                const response = await axios.post(`${config.apiBaseUrl}/login`, {username, password}, {withCredentials: true});
                const {access_token} = response.data;
                localStorage.setItem('access_token', access_token);
                localStorage.setItem('username', username);
                onLogin();
                navigate('/fundamentals-docs');
            }
        } catch (err) {
            setError(err.response ? err.response.data.detail : 'Login failed');
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div className="login-container">
            {loading && (
                <div className="loading-overlay">
                    <div className="loading-content">
                        <div className="spinner"></div>
                        <p>{isRegistering ? 'Registering...' : 'Logging in...'}</p>
                    </div>
                </div>
            )}
            <div className="login-form">
                <h2>{isRegistering ? 'Register' : 'Log in'}</h2>
                {error && <div className="error-message">{error}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Username</label>
                        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)}
                               placeholder="Enter username"/>
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}
                               placeholder="Enter password"/>
                    </div>
                    {isRegistering && (
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                                   placeholder="Enter email"/>
                        </div>
                    )}
                    <button type="submit" className="login-button" disabled={loading}>
                        {isRegistering ? 'Register' : 'Log in'}
                    </button>
                </form>
                <div className="toggle-form">
                    {isRegistering ? (
                        <span>Already have an account? <button
                            onClick={() => setIsRegistering(false)}>Log in</button></span>
                    ) : (
                        <span>Don't have an account? <button
                            onClick={() => setIsRegistering(true)}>Register</button></span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Login;
