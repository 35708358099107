import React from 'react';
import { Card, Tabs, Tab } from 'react-bootstrap';
import './Fundamentals.css';

const FundamentalsDocumentation = () => {
    return (
        <div className="fundamentals-documentation">
            <h1>Fundamentals API Documentation</h1>
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Interfacing with Cash Flow, Income, and Balance Sheet Routes</Card.Title>
                    <div>
                        <h3>Endpoints</h3>
                        <p>The following endpoints are available for fetching financial data:</p>
                        <ul>
                            <li><code>/financials/cash_flow</code></li>
                            <li><code>/financials/balance_sheet</code></li>
                            <li><code>/financials/income</code></li>
                        </ul>
                        <h3>Query Parameters</h3>
                        <p>Each endpoint allows you to specify various query parameters to filter the data:</p>
                        <ul>
                            <li><strong>symbol</strong> (optional): Stock symbol (e.g., "AAPL")</li>
                            <li><strong>report_date</strong> (optional): Report date in YYYY-MM-DD format</li>
                            <li><strong>filing_date</strong> (optional): Filing date in YYYY-MM-DD format</li>
                            <li><strong>latest_n_records</strong> (optional): Fetch the latest N records</li>
                        </ul>
                        <h3>Authentication</h3>
                        <p>
                            Our API requires authentication to ensure that only authorized users can access the data. You can authenticate by including your API key in the headers of your requests using the <code>X-API-KEY</code> header.
                        </p>
                        <p><strong>Why <code>X-API-KEY</code>?</strong></p>
                        <p>
                            The <code>X-API-KEY</code> header is commonly used for API key authentication. It is not a strict standard but a widely accepted convention. This approach helps in separating authentication keys from other request parameters, enhancing security.
                        </p>
                        <h3>Example API Requests</h3>
                        <Tabs defaultActiveKey="curl" id="api-examples-tabs">
                            <Tab eventKey="curl" title="cURL">
                                <h4>Fetch Cash Flow Data</h4>
                                <pre>{`curl -X GET "https://api.equityexplorer.io/financials/cash_flow?symbol=AAPL&latest_n_records=5" \\
-H "X-API-KEY: YOUR_API_KEY"`}</pre>
                                <h4>Fetch Balance Sheet Data</h4>
                                <pre>{`curl -X GET "https://api.equityexplorer.io/financials/balance_sheet?symbol=MSFT&report_date=2022-12-31" \\
-H "X-API-KEY: YOUR_API_KEY"`}</pre>
                                <h4>Fetch Income Statement Data</h4>
                                <pre>{`curl -X GET "https://api.equityexplorer.io/financials/income?filing_date=2023-01-31&latest_n_records=5" \\
-H "X-API-KEY: YOUR_API_KEY"`}</pre>
                            </Tab>
                            <Tab eventKey="python" title="Python">
                                <h4>Fetch Cash Flow Data</h4>
                                <pre>{`import requests

url = 'https://api.equityexplorer.io/financials/cash_flow'
headers = {'X-API-KEY': 'YOUR_API_KEY'}
params = {'symbol': 'AAPL', 'latest_n_records': 5}

response = requests.get(url, headers=headers, params=params)
print(response.json())`}</pre>
                                <h4>Fetch Balance Sheet Data</h4>
                                <pre>{`import requests

url = 'https://api.equityexplorer.io/financials/balance_sheet'
headers = {'X-API-KEY': 'YOUR_API_KEY'}
params = {'symbol': 'MSFT', 'report_date': '2022-12-31'}

response = requests.get(url, headers=headers, params=params)
print(response.json())`}</pre>
                                <h4>Fetch Income Statement Data</h4>
                                <pre>{`import requests

url = 'https://api.equityexplorer.io/financials/income'
headers = {'X-API-KEY': 'YOUR_API_KEY'}
params = {'filing_date': '2023-01-31', 'latest_n_records': 5}

response = requests.get(url, headers=headers, params=params)
print(response.json())`}</pre>
                            </Tab>
                            <Tab eventKey="javascript" title="JavaScript">
                                <h4>Fetch Cash Flow Data</h4>
                                <pre>{`const axios = require('axios');

const url = 'https://api.equityexplorer.io/financials/cash_flow';
const headers = {
    'X-API-KEY': 'YOUR_API_KEY'
};
const params = {
    symbol: 'AAPL',
    latest_n_records: 5
};

axios.get(url, { headers, params })
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        console.error(error);
    });`}</pre>
                                <h4>Fetch Balance Sheet Data</h4>
                                <pre>{`const axios = require('axios');

const url = 'https://api.equityexplorer.io/financials/balance_sheet';
const headers = {
    'X-API-KEY': 'YOUR_API_KEY'
};
const params = {
    symbol: 'MSFT',
    report_date: '2022-12-31'
};

axios.get(url, { headers, params })
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        console.error(error);
    });`}</pre>
                                <h4>Fetch Income Statement Data</h4>
                                <pre>{`const axios = require('axios');

const url = 'https://api.equityexplorer.io/financials/income';
const headers = {
    'X-API-KEY': 'YOUR_API_KEY'
};
const params = {
    filing_date: '2023-01-31',
    latest_n_records: 5
};

axios.get(url, { headers, params })
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        console.error(error);
    });`}</pre>
                            </Tab>
                        </Tabs>
                        <h3>Resources for Further Learning</h3>
                        <ul>
                            <li><a href="https://restfulapi.net/" target="_blank" rel="noopener noreferrer">REST API Tutorial</a></li>
                            <li><a href="https://docs.github.com/en/rest" target="_blank" rel="noopener noreferrer">Introduction to REST APIs on GitHub</a></li>
                        </ul>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default FundamentalsDocumentation;
