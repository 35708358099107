import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import config from '../../config';
import './Account.css';
import {refreshAccessToken} from '../../Components/Auth/authUtils';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';
import {Bar} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import {CopyToClipboard} from "react-copy-to-clipboard/src";

// Register the necessary Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Account = () => {
    const [accountInfo, setAccountInfo] = useState(null);
    const [error, setError] = useState('');
    const hasFetchedData = useRef(false);
    const navigate = useNavigate();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (hasFetchedData.current) return;

        const fetchAccountInfo = async () => {
            hasFetchedData.current = true;
            try {
                const token = localStorage.getItem('access_token');
                let response = await axios.get(`${config.apiBaseUrl}/account`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setAccountInfo(response.data);
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    try {
                        const refreshToken = await refreshAccessToken();
                        localStorage.setItem('access_token', refreshToken);
                        let response = await axios.get(`${config.apiBaseUrl}/account`, {
                            headers: {
                                'Authorization': `Bearer ${refreshToken}`
                            }
                        });
                        setAccountInfo(response.data);
                    } catch (refreshError) {
                        localStorage.removeItem('access_token');
                        localStorage.removeItem('username');
                        navigate('/login');
                        alert('Session expired. Please log in again.');
                    }
                } else {
                    setError('Failed to fetch account information.');
                }
            }
        };

        fetchAccountInfo();
    }, [navigate]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!accountInfo) {
        return <div>No account information available.</div>;
    }

    const data = {
        labels: ['Cash Flow', 'Balance Sheet', 'Income Statement', 'Stock Prices'],
        // 'Metadata'],
        datasets: [
            {
                label: 'API Usage Count',
                data: [
                    accountInfo.cash_flow_route_count,
                    accountInfo.balance_sheet_route_count,
                    accountInfo.income_statement_route_count,
                    accountInfo.stock_prices_route_count,
                    // accountInfo.metadata_route_count
                ],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(255, 159, 64, 0.6)'
                ],
                borderWidth: 1
            }
        ]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    // Calculate the current bill
    const calculateBill = (cashFlow, balanceSheet, incomeStatement) => {
        const totalRecords = cashFlow + balanceSheet + incomeStatement;
        const cost = totalRecords / 1000;
        return cost.toFixed(2); // Convert to a string with 2 decimal places
    };

    const currentBill = calculateBill(
        accountInfo.cash_flow_route_count,
        accountInfo.balance_sheet_route_count,
        accountInfo.income_statement_route_count
    );

    return (
        <Container className="account-container">
            <Row>
                <Col>
                    <Card className="mb-3 card-custom">
                        <Card.Body>
                            <Card.Title className="text-center mb-4">Account Information</Card.Title>
                            <p><strong>Username:</strong> {accountInfo.username}</p>
                            <p><strong>Email:</strong> {accountInfo.email}</p>
                            <p><strong>API Key:</strong></p>
                            <div className="api-key-container" style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '5px' }}>
                    <pre style={{ marginBottom: '0', overflowX: 'auto', backgroundColor: 'transparent' }}>
                        {accountInfo.api_key}
                    </pre>
                                <CopyToClipboard text={accountInfo.api_key} onCopy={() => setCopied(true)}>
                                    <Button variant="outline-secondary" size="sm" style={{ marginLeft: '10px' }}>
                                        {copied ? "Copied!" : "Copy"}
                                    </Button>
                                </CopyToClipboard>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-3 card-custom">
                        <Card.Body>
                            <Card.Title>API Usage</Card.Title>
                            <p><strong>Cash Flow Records Consumed:</strong> {accountInfo.cash_flow_route_count}</p>
                            <p><strong>Balance Sheet Records Consumed:</strong> {accountInfo.balance_sheet_route_count}
                            </p>
                            <p><strong>Income Statement Records
                                Consumed:</strong> {accountInfo.income_statement_route_count}</p>
                            <p><strong>Stock Prices Records Consumed:</strong> {accountInfo.stock_prices_route_count}
                            </p>
                            <p><strong>Metadata Records Consumed:</strong> {accountInfo.metadata_route_count}</p>
                            <div className="chart-container">
                                <Bar className="bar-chart" data={data} options={options}/>
                            </div>
                            <h3>Current Bill: ${currentBill}</h3>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Account;
