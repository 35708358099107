// src/views/Home.js
import React from 'react';
import Header from '../../Components/Headers/Header';
import SearchBar from '../..//Components/SearchBar/SearchBar';
import Card from '../..//Components/Cards/Card';

const Home = () => {

    return (
        <div>
            <div className="card-container">
                Welcome!
                {/* Add more cards */}
            </div>
        </div>
    );
};

export default Home;