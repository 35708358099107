import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from "./Views/HomePage/HomePage";
import Login from './Components/Auth/Login';
import ProtectedRoute from './Components/Auth/ProtectedRoute';
import IntroductionSection from './Components/Docs/APIInterface';
import About from './Components/Docs/About';
import Layout from './Components/Layout/Layout';
import BalanceSheets from "./Components/Stocks/BalanceSheets/BalanceSheet";
import Account from './Views/Account/Account';
import CashFlow from "./Components/Stocks/CashFlow/CashFlow";
import Income from "./Components/Stocks/Income/Income";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import FundamentalsDocumentation from "./Components/Docs/Fundamentals";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('access_token'));

    const handleLogin = () => {
        setIsLoggedIn(true);
    };

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login onLogin={handleLogin}/>}/>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <HomePage/>
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/api-basics"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <IntroductionSection/>
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route path="/fundamentals-docs"
                       element={
                           <ProtectedRoute>
                               <Layout>
                                   <FundamentalsDocumentation/>
                               </Layout>
                           </ProtectedRoute>
                       }
                />
                <Route
                    path="/about"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <About/>
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/balance-sheets"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <BalanceSheets/>
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/cash-flow"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <CashFlow/>
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/income"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <Income/>
                            </Layout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/account"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <Account/>
                            </Layout>
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;
