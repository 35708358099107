import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';

const CashFlowStatementInfo = () => {
    return (
        <Container>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>What is a Cash Flow Statement?</Card.Title>
                            <Card.Text>
                                A <strong>cash flow statement (CFS)</strong> is a financial statement that provides a summary of the cash and cash equivalents entering and leaving a company during a specific period. It offers a detailed picture of how well a company manages its cash position, showing how well the company generates cash to pay its debt obligations and fund its operating expenses.
                            </Card.Text>
                            <Card.Text>
                                The CFS complements the balance sheet and the income statement and is essential for determining a company's liquidity and financial health. It helps investors understand whether a company is on solid financial ground by showing the cash generated and used in operating, investing, and financing activities.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Components of a Cash Flow Statement</Card.Title>
                            <Card.Text>The CFS is divided into three main sections:</Card.Text>
                            <ul>
                                <li><strong>Operating Activities:</strong> Cash generated or spent in the course of regular business operations. This includes receipts from sales, payments to suppliers, wages, rent, and other operating expenses.</li>
                                <li><strong>Investing Activities:</strong> Cash used for or generated from investments in long-term assets, such as property, equipment, and securities. This section shows the cash outflows for purchases of assets and cash inflows from the sale of assets.</li>
                                <li><strong>Financing Activities:</strong> Cash flows related to raising and repaying capital. This includes proceeds from issuing stock or debt, repayments of loans, and dividend payments.</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Methods of Preparing a Cash Flow Statement</Card.Title>
                            <Card.Text>There are two primary methods for preparing a cash flow statement:</Card.Text>
                            <ul>
                                <li><strong>Direct Method:</strong> This method lists all the major operating cash receipts and payments during the period. It is straightforward but requires detailed records of all cash transactions.</li>
                                <li><strong>Indirect Method:</strong> This method starts with net income and adjusts for changes in balance sheet accounts to calculate the cash flow from operating activities. It is more commonly used as it is easier to prepare from existing financial statements.</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Importance of the Cash Flow Statement</Card.Title>
                            <Card.Text>The CFS is crucial for several reasons:</Card.Text>
                            <ul>
                                <li>It helps determine a company's ability to generate future cash flow.</li>
                                <li>It provides insights into a company's liquidity and solvency, helping assess whether it can meet its obligations.</li>
                                <li>It highlights changes in assets, liabilities, and equity, providing a comprehensive view of financial health.</li>
                                <li>Investors and analysts use it to evaluate a company's financial stability and to make informed investment decisions.</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Example of a Cash Flow Statement</Card.Title>
                            <Card.Text>Here is a simplified example of a cash flow statement:</Card.Text>
                            <pre>
                                {`
Cash Flow from Operating Activities:
  Net Income: $50,000
  Depreciation: $5,000
  Increase in Accounts Receivable: ($2,000)
  Increase in Inventory: ($3,000)
  Net Cash from Operating Activities: $50,000

Cash Flow from Investing Activities:
  Purchase of Equipment: ($10,000)
  Sale of Investments: $2,000
  Net Cash from Investing Activities: ($8,000)

Cash Flow from Financing Activities:
  Issuance of Stock: $20,000
  Repayment of Debt: ($5,000)
  Net Cash from Financing Activities: $15,000

Net Increase in Cash: $57,000
                                `}
                            </pre>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CashFlowStatementInfo;
