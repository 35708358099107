import axios from 'axios';
import config from '../../config';


export const logout = (navigate) => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('username');
    navigate('/login'); // Assuming '/login' is your login route
};

export const refreshAccessToken = async () => {
    try {
        const response = await axios.post(
            `${config.apiBaseUrl}/refresh`,
            {},
            {withCredentials: true}
        );
        return response.data.access_token;
    } catch (error) {
        throw new Error('Refresh token expired');
    }
};