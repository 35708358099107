import React from 'react';
import { Card, Tabs, Tab } from 'react-bootstrap';
import './APIInterface.css';

const IntroductionSection = () => {
    return (
        <div className="api-interface">
            <h1>Financial Data API Interface</h1>
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Introduction to APIs</Card.Title>
                    <div>
                        <h3>What is an API?</h3>
                        <p>
                            An <strong>API (Application Programming Interface)</strong> is a set of rules and protocols that
                            allows different software applications to communicate with each other. APIs enable the
                            integration of various software components, facilitating the sharing of data and functionality
                            across applications.
                        </p>
                        <h3>What is a RESTful API?</h3>
                        <p>
                            A <strong>RESTful API</strong> (Representational State Transfer) is an architectural style for
                            designing networked applications. It relies on a stateless, client-server communication
                            protocol—usually HTTP. RESTful APIs use standard HTTP methods such as GET, POST, PUT, and DELETE
                            to perform CRUD (Create, Read, Update, Delete) operations on resources.
                        </p>
                        <h3>Key Concepts of RESTful APIs</h3>
                        <ul>
                            <li><strong>Resources</strong>: Objects or representations of information that are accessible
                                via a unique URI (Uniform Resource Identifier). For example, <code>/financials/balance_sheet</code> could be a resource representing balance sheet data.
                            </li>
                            <li><strong>HTTP Methods</strong>: Actions that can be performed on resources.
                                <ul>
                                    <li><strong>GET</strong>: Retrieve a resource.</li>
                                    <li><strong>POST</strong>: Create a new resource.</li>
                                    <li><strong>PUT</strong>: Update an existing resource.</li>
                                    <li><strong>DELETE</strong>: Remove a resource.</li>
                                </ul>
                            </li>
                            <li><strong>Statelessness</strong>: Each API request from a client contains all the information
                                needed to fulfill the request. The server does not store any client context between
                                requests.
                            </li>
                            <li><strong>JSON</strong>: JavaScript Object Notation is commonly used to format the data
                                exchanged between client and server.
                            </li>
                        </ul>
                        <h3>How to Use Our Financial Data API</h3>
                        <p>
                            Our Financial Data API provides endpoints to fetch financial data such as cash flow, balance
                            sheet, and income statements. Below are examples of how to use the API.
                        </p>
                        <p><strong>Base URL</strong>: <code>https://api.equityexplorer.io</code></p>
                        <p><strong>Endpoints</strong>:</p>
                        <ul>
                            <li><code>/financials/cash_flow</code></li>
                            <li><code>/financials/balance_sheet</code></li>
                            <li><code>/financials/income</code></li>
                            <li><code>/metadata</code></li>
                        </ul>
                        <p>Each endpoint allows you to specify various query parameters to filter the data:</p>
                        <ul>
                            <li><strong>symbol</strong> (optional): Stock symbol (e.g., "AAPL")</li>
                            <li><strong>report_date</strong> (optional): Report date in YYYY-MM-DD format</li>
                            <li><strong>filing_date</strong> (optional): Filing date in YYYY-MM-DD format</li>
                            <li><strong>latest_n_records</strong> (optional): Fetch the latest N records</li>
                        </ul>
                        <h3>Example API Requests</h3>
                        <Tabs defaultActiveKey="curl" id="api-examples-tabs">
                            <Tab eventKey="curl" title="cURL">
                                <p><strong>Fetch Cash Flow Data:</strong></p>
                                <pre>{`curl -X GET "https://api.equityexplorer.io/financials/cash_flow?symbol=AAPL&latest_n_records=5" \\
-H "X-API-Key: YOUR_API_KEY"`}</pre>
                            </Tab>
                            <Tab eventKey="python" title="Python">
                                <p><strong>Fetch Cash Flow Data:</strong></p>
                                <pre>{`import requests

url = 'https://api.equityexplorer.io/financials/cash_flow'
headers = {'X-API-Key': 'YOUR_API_KEY'}
params = {'symbol': 'AAPL', 'latest_n_records': 5}

response = requests.get(url, headers=headers, params=params)
print(response.json())`}</pre>
                            </Tab>
                            <Tab eventKey="javascript" title="JavaScript">
                                <p><strong>Fetch Cash Flow Data:</strong></p>
                                <pre>{`const axios = require('axios');

const url = 'https://api.equityexplorer.io/financials/cash_flow';
const headers = {
    'X-API-Key': 'YOUR_API_KEY'
};
const params = {
    symbol: 'AAPL',
    latest_n_records: 5
};

axios.get(url, { headers, params })
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        console.error(error);
    });`}</pre>
                            </Tab>
                        </Tabs>
                        <h3>Authentication</h3>
                        <p>
                            Our API requires authentication to ensure that only authorized users can access the data. You can authenticate by including your API key in the headers of your requests.
                        </p>
                        <p><strong>Why Not in the URL?</strong></p>
                        <p>
                            Including the API key in the URL is not secure because URLs can be logged in various places such as server logs, browser history, and network monitoring tools. By placing the API key in the headers, we ensure that it is not exposed in these places, thereby providing an additional layer of security.
                        </p>
                        <h3>Tips for Using the API</h3>
                        <ul>
                            <li><strong>Authentication</strong>: Always include your API key in the headers of your requests to authenticate and access the data.
                            </li>
                            <li><strong>Error Handling</strong>: Handle potential errors gracefully by checking for HTTP
                                status codes and implementing retry logic if necessary.
                            </li>
                            <li><strong>Rate Limiting</strong>: Respect any rate limits imposed by the API to avoid being
                                throttled or banned. This information is typically available in the API documentation.
                            </li>
                        </ul>
                        <h3>Resources for Further Learning</h3>
                        <ul>
                            <li><a href="https://restfulapi.net/" target="_blank" rel="noopener noreferrer">REST API
                                Tutorial</a></li>
                            <li><a href="https://docs.github.com/en/rest" target="_blank" rel="noopener noreferrer">Introduction
                                to REST APIs on GitHub</a></li>
                        </ul>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default IntroductionSection;
