import React, {useState, useEffect} from 'react';
import {useTable, useFilters, useSortBy, usePagination} from 'react-table';
import {CSVLink} from 'react-csv';
import {Card, Form, Button, Container, Row, Col, Badge} from 'react-bootstrap';
import {refreshAccessToken} from '../../Auth/authUtils'

import './BalanceSheet.css';
import config from "../../../config";
import axios from "axios";
import {useNavigate} from "react-router-dom"; // Import custom CSS

const BalanceSheets = () => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [symbolInput, setSymbolInput] = useState('');
    const [symbols, setSymbols] = useState([]);
    const navigate = useNavigate();

    const fetchData = async (symbolsToFetch) => {
        setLoading(true);
        setError(null);
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${config.apiBaseUrl}/financials/balance_sheet`, {
                headers: {'Authorization': `Bearer ${token}`},
                params: {symbol: symbolsToFetch.join(',')}
            });
            const financialData = response.data;

            if (financialData.length > 0) {
                if (columns.length === 0) {
                    const newColumns = [
                        {Header: 'Symbol', accessor: 'symbol'},
                        {Header: 'Report Date', accessor: 'report_date'},
                        {Header: 'Filing Date', accessor: 'filing_date'},
                        ...Object.keys(financialData[0].data[0]).map(key => ({
                            Header: key,
                            accessor: key
                        }))
                    ];
                    setColumns(newColumns);
                }

                const flattenedData = financialData.map(record => ({
                    symbol: record.symbol,
                    report_date: record.report_date,
                    filing_date: record.filing_date,
                    ...record.data[0]
                }));

                setData(prevData => [...prevData, ...flattenedData]);
            } else {
                setError("No data found for the provided symbols.");
            }
        } catch (err) {
            if (err.response && err.response.status === 401) {
                try {
                    const refreshToken = await refreshAccessToken();
                    localStorage.setItem('access_token', refreshToken);
                    await fetchData(symbolsToFetch);
                } catch (refreshError) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('username');
                    navigate('/login');
                    alert('Session expired. Please log in again.');
                }
            } else {
                setError(err.message);
            }
        }
        setLoading(false);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const newSymbol = symbolInput.trim();
        if (newSymbol && !symbols.includes(newSymbol)) {
            setSymbols([...symbols, newSymbol]);
            fetchData([newSymbol]);
            setSymbolInput('');
        }
    };

    const removeSymbol = (symbol) => {
        const updatedSymbols = symbols.filter(s => s !== symbol);
        setSymbols(updatedSymbols);

        // Filter data to remove entries corresponding to the removed symbol
        setData(prevData => prevData.filter(record => record.symbol !== symbol));
    };

    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {columns, data, defaultColumn, initialState: {pageIndex: 0}},
        useFilters,
        useSortBy,
        usePagination
    );

    return (
        <Container className="my-5">
            <Card className="mt-4">
                <Card.Header as="h5">Balance Sheet Overview</Card.Header>
                <Card.Body>
                    <Card.Text>
                        A balance sheet provides a snapshot of a company's financial position at a specific point in
                        time.
                        It consists of three main components: assets, liabilities, and shareholders' equity.
                    </Card.Text>
                    <Card.Text>
                        <strong>Assets:</strong> Resources owned by the company that have economic value.
                    </Card.Text>
                    <Card.Text>
                        <strong>Liabilities:</strong> Obligations the company owes to outsiders.
                    </Card.Text>
                    <Card.Text>
                        <strong>Shareholders' Equity:</strong> The residual interest in the assets of the company after
                        deducting liabilities.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card className="mt-4">
                <Card.Header as="h5">Understanding Balance Sheet Items</Card.Header>
                <Card.Body>
                    <Card.Text>
                        <strong>Current Assets:</strong> Cash, cash equivalents, and other assets expected to be
                        converted to cash within a year.
                    </Card.Text>
                    <Card.Text>
                        <strong>Non-Current Assets:</strong> Long-term investments, property, plant, and equipment, and
                        intangible assets.
                    </Card.Text>
                    <Card.Text>
                        <strong>Current Liabilities:</strong> Obligations the company needs to pay within a year.
                    </Card.Text>
                    <Card.Text>
                        <strong>Non-Current Liabilities:</strong> Long-term debt and other obligations not due within
                        the next year.
                    </Card.Text>
                    <Card.Text>
                        <strong>Shareholders' Equity:</strong> Includes common stock, retained earnings, and additional
                        paid-in capital.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card className="mb-4">
                <Card.Header as="h5">Balance Sheets</Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSearch} className="mb-4">
                        <Row>
                            <Col md={8}>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter stock symbols"
                                        value={symbolInput}
                                        onChange={(e) => setSymbolInput(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Button variant="primary" type="submit">
                                    Search
                                </Button>
                            </Col>
                            <Col md={2}>
                                <CSVLink data={data} filename="balance_sheets.csv" className="btn btn-secondary">
                                    Download CSV
                                </CSVLink>
                            </Col>
                        </Row>
                    </Form>
                    <div className="search-results mb-4">
                        {symbols.map((symbol, index) => (
                            <Badge key={index} pill variant="primary" className="mr-2">
                                {symbol} <span onClick={() => removeSymbol(symbol)} className="badge-remove">x</span>
                            </Badge>
                        ))}
                    </div>
                    {loading && <p>Loading...</p>}
                    {error && <p>Error: {error}</p>}
                    <div className="table-responsive">
                        <table {...getTableProps()} className="table table-striped table-bordered">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination">
                        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                        </Button>
                        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </Button>
                        <Button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </Button>
                        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                        </Button>
                        <span>
                        Page{' '}
                            <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                        <span>
                        | Go to page:{' '}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                                style={{width: '100px'}}
                            />
                    </span>
                        <select
                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </Card.Body>
            </Card>

        </Container>
    );

}

// Default filter UI for each column
function DefaultColumnFilter({
                                 column: {filterValue, preFilteredRows, setFilter},
                             }) {
    return (
        <Form.Control
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search...`}
        />
    );
}

export default BalanceSheets;
