import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';

const Income = () => {
    return (
        <Container>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>What is an Income Statement?</Card.Title>
                            <Card.Text>
                                An <strong>income statement</strong>, also known as a profit and loss (P&L) statement, is a financial document that shows a company’s revenues, expenses, and profits or losses over a specific period. It provides a detailed account of how the company’s revenue is transformed into net income.
                            </Card.Text>
                            <Card.Text>
                                The income statement is one of the three main financial statements, along with the balance sheet and cash flow statement, and is crucial for understanding a company's financial performance.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Components of an Income Statement</Card.Title>
                            <Card.Text>The income statement typically includes the following sections:</Card.Text>
                            <ul>
                                <li><strong>Revenue/Sales:</strong> The total income generated from selling goods or services. This is the top line of the income statement.</li>
                                <li><strong>Cost of Goods Sold (COGS):</strong> The direct costs attributable to the production of the goods sold by the company. This includes raw materials and labor costs.</li>
                                <li><strong>Gross Profit:</strong> Calculated by subtracting COGS from revenue. It represents the profit a company makes after deducting the costs associated with making and selling its products.</li>
                                <li><strong>Operating Expenses:</strong> The costs required to run the business that are not directly tied to the production of goods or services. This includes rent, utilities, payroll, and marketing expenses.</li>
                                <li><strong>Operating Income:</strong> Also known as operating profit, it is the profit earned from regular business operations. It is calculated by subtracting operating expenses from gross profit.</li>
                                <li><strong>Non-Operating Items:</strong> These include revenues and expenses not related to the core business operations, such as interest income, dividend income, and gains or losses from investments.</li>
                                <li><strong>Net Income:</strong> The final profit or loss after all revenues and expenses have been accounted for, including taxes. This is often referred to as the bottom line.</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Methods of Preparing an Income Statement</Card.Title>
                            <Card.Text>There are two primary methods for preparing an income statement:</Card.Text>
                            <ul>
                                <li><strong>Single-Step Income Statement:</strong> This method sums up all revenues and subtracts all expenses to find the net income. It is simpler but provides less detail.</li>
                                <li><strong>Multi-Step Income Statement:</strong> This method separates operating revenues and expenses from non-operating ones and calculates multiple levels of profitability, such as gross profit, operating income, and net income. It provides a more detailed analysis of a company's financial performance.</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Importance of the Income Statement</Card.Title>
                            <Card.Text>The income statement is crucial for several reasons:</Card.Text>
                            <ul>
                                <li>It helps determine the company’s ability to generate profit from its operations.</li>
                                <li>It provides insights into the efficiency of the company’s operations and management.</li>
                                <li>Investors and creditors use it to evaluate the financial health and profitability of a company.</li>
                                <li>It helps in comparing the performance of the company over different periods and with other companies in the industry.</li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Example of an Income Statement</Card.Title>
                            <Card.Text>Here is a simplified example of an income statement:</Card.Text>
                            <pre>
                                {`
Revenue: $100,000
Cost of Goods Sold: $40,000
Gross Profit: $60,000

Operating Expenses:
  - Rent: $10,000
  - Utilities: $5,000
  - Payroll: $15,000
  - Marketing: $5,000
Total Operating Expenses: $35,000

Operating Income: $25,000

Non-Operating Items:
  - Interest Income: $2,000
  - Dividend Income: $1,000
Total Non-Operating Items: $3,000

Income Before Taxes: $28,000
Taxes: $8,000

Net Income: $20,000
                                `}
                            </pre>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Income;
