// src/components/Cards/Card.js
import React from 'react';
import './Card.css';

const Card = ({ title, content }) => {
    return (
        <div className="card h-100">
            <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <div className="card-text">{content}</div>
            </div>
        </div>
    );
};

export default Card;
