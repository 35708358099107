// src/Components/Layout.js
import React from 'react';
import Header from '../Headers/Header';

const Layout = ({ children }) => {
    return (
        <div className="app-container">
            <Header />
            <div className="main-content">
                {children}
            </div>
        </div>
    );
};

export default Layout;
