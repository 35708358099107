import React from 'react';
import Card from '../Cards/Card';
import './About.css';

const About = () => {
    return (
        <div className="container my-5">
            <div className="row">
                <div className="col-md-4 mb-4">
                    <Card
                        title="About"
                        content={<p>Welcome to Equity Explorer, your go-to source for accurate and affordable stock data directly extracted from SEC quarterly reports. Our mission is to make financial data accessible to everyone by providing a comprehensive and user-friendly platform.</p>}
                    />
                </div>
                <div className="col-md-4 mb-4">
                    <Card
                        title="What We Do"
                        content={
                            <ul>
                                <li>Balance Sheets</li>
                                <li>Income Statements</li>
                                <li>Cash Flow Statements</li>
                            </ul>
                        }
                    />
                </div>
                <div className="col-md-4 mb-4">
                    <Card
                        title="Features"
                        content={
                            <ul>
                                <li>Browse Financial Data: Easily navigate through our extensive catalog of financial data directly from our user interface.</li>
                                <li>RESTful API Access: Programmatically access our data via a robust and well-documented RESTful API.</li>
                                <li>Data Validation: Each data point is meticulously validated upon entry to ensure the highest level of accuracy.</li>
                            </ul>
                        }
                    />
                </div>
                <div className="col-md-4 mb-4">
                    <Card
                        title="Why Choose Us?"
                        content={<p>Our platform aims to provide affordable access to financial data, providing reliable stock data to developers, analysts, and financial enthusiasts alike.</p>}
                    />
                </div>
                <div className="col-md-4 mb-4">
                    <Card
                        title="Future Plans"
                        content={
                            <>
                                <ul>
                                    <li>End of Day stock prices</li>
                                    <li>ETF holdings</li>
                                    <li>ETF analytics (using data sourced from quarterly reports)</li>
                                </ul>
                                <p>While our data is currently free to use, we plan to monetize the platform in the future to support operating costs, ensuring continuous improvements and the addition of new features.</p>
                            </>
                        }
                    />
                </div>
                <div className="col-md-4 mb-4">
                    <Card
                        title="Frequently Asked Questions"
                        content={
                            <>
                                <h3>How is the data sourced and validated?</h3>
                                <p>We source our data directly from the SEC’s publicly available reports. Our team then validates this data to ensure its accuracy and reliability.</p>
                                <h3>What types of financial statements are included?</h3>
                                <p>Our platform provides access to balance sheets, income statements, and cash flow statements extracted from quarterly SEC reports.</p>
                                <h3>Can I access the data programmatically?</h3>
                                <p>Yes, our platform offers a RESTful API that allows for seamless programmatic access to our financial data. Comprehensive documentation and example queries are available to help you get started.</p>
                                <h3>Is the data adjusted for splits or dividends?</h3>
                                <p>Currently, our primary focus is on providing raw financial data. Adjustments for splits or dividends will be considered as part of our future enhancements.</p>
                                <h3>What are the limitations of the free tier?</h3>
                                <p>Our free tier provides full access to all available data, but may have usage limits to ensure fair access for all users. Details on limits and pricing for higher usage tiers will be available in the future.</p>
                                <h3>What support options are available?</h3>
                                <p>We offer extensive documentation and a knowledge base for self-service support. For further assistance, our support team can be contacted via email.</p>
                            </>
                        }
                    />
                </div>
                <div className="col-md-4 mb-4">
                    <Card
                        title="Legal Considerations"
                        content={<p>The data is publicly available through the SEC, and our goal is to maintain transparency and accessibility for all users. You may not redistribute this data for commercial use.</p>}
                    />
                </div>
            </div>
        </div>
    );
};

export default About;
